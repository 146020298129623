// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"
import { Sortable } from "sortablejs"
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this)
    })
  }

  async end(event){
    let id = event.item.dataset.id
    let data = new FormData()

    data.append("position", event.newIndex + 1)

    const request = new FetchRequest(
      "patch", 
      this.data.get("url").replace(":id",id), 
      { body: data }, 
      { responseKind: "turbo-stream" }
    )

    const response = await request.perform()

    if (!response.ok) {
      console.error("Failed to update position", await response.text())
    }

  }
}
