import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["textField"];

  connect() {
    this.toggleTextField(); // Initialize visibility on page load
  }

  toggleTextField() {
    if (this.checkbox.checked) {
      this.textFieldTarget.style.display = "block";
    } else {
      this.textFieldTarget.style.display = "none";
    }
  }

  get checkbox() {
    return this.element.querySelector("input[type='checkbox']");
  }
}