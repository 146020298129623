// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "@hotwired/turbo-rails";
require("@rails/activestorage").start();
import LocalTime from "local-time";
import "trix";
import "@rails/actiontext";

// Load Stimulus controllers
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
import "chartkick/chart.js"
import Clipboard from "@stimulus-components/clipboard"

const application = Application.start();
application.register('clipboard', Clipboard)
const context = require.context("./controllers", true, /_controller\.js$/);

application.load(definitionsFromContext(context));

// Import all files from src folder
const requireSrc = require.context("./src", true, /\.(js|jsx)$/);
requireSrc.keys().forEach(requireSrc);

import * as bootstrap from "bootstrap";

LocalTime.start();


document.addEventListener("turbo:load", () => {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  // var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
  //   return new bootstrap.Popover(popoverTriggerEl);
  // });
});

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application
