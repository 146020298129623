import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["iframe", "container"];

  connect() {
    const iframe = this.iframeTarget;
    const iframeUrl = iframe.getAttribute("src");

    fetch(`/iframe_check?url=${encodeURIComponent(iframeUrl)}`)
      .then(response => response.json())
      .then(data => {
        if (data.embeddable) {
          iframe.style.display = "block";
          this.hideError();
        } else {
          this.displayError();
        }
      })
      .catch(() => {
        this.displayError();
      });
  }

  handleLoad() {
    this.iframeTarget.style.display = "block";
    this.hideError();
  }

  handleError() {
    this.displayError();
  }

  displayError() {
    this.containerTarget.querySelector("#error-message").style.display = "block";
    this.iframeTarget.style.display = "none";
  }

  hideError() {
    this.containerTarget.querySelector("#error-message").style.display = "none";
  }
}
